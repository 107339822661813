/**
 * Current status of the clients internet connection
 */
export enum ConnectionStatus {
  CONNECTING = "CONNECTING",
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  /**
   * Indicates that a ping event was sent to the server, but that the server did not successfully return a 'pong' response.
   *
   * These message events are used to ensure that the client/server are still connected - but if there has been no pong response in a certain period
   * of time, we should log this to help detect if the client and server are no longer connected.
   * https://developer.mozilla.org/en-US/docs/Web/API/WebSockets_API/Writing_WebSocket_servers#pings_and_pongs_the_heartbeat_of_websockets
   */
  DEGRADED_CONNECTION = "DEGRADED_CONNECTION",
}

/**
 * Provides a strict ordering of connection stasuses from best to worst.
 *
 * Useful for tie breaking statuses from different sources.
 */
export const CONNECTION_STATUS_ORDERING = [
  ConnectionStatus.CONNECTED,
  ConnectionStatus.CONNECTING,
  ConnectionStatus.DEGRADED_CONNECTION,
  ConnectionStatus.DISCONNECTED,
];
