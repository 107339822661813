/* eslint-disable max-lines-per-function -- includes theme vars */
/* eslint-disable no-restricted-properties */
import {
  ConditionalFormattingCellStyle,
  ConditionalFormattingGradientStyle,
  HexColor,
} from "@hex/common";
// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization, no-restricted-imports
import { merge } from "lodash";
import type { editor as Editor } from "monaco-editor";
import { darken, lighten, mix, rgba } from "polished";
import { css } from "styled-components";

import sharedStyles, {
  generateMarkdownCodeTheme,
  generateMonacoRules,
  markdownStyles,
  monacoPillCss,
  pillCss,
} from "../common/sharedThemeStyles";
import type {
  BaseThemeVariables,
  CodeColorsType,
  PartialThemeVariables,
  Theme,
} from "../common/theme";

export const getDarkModeVariables = (): BaseThemeVariables => {
  const ColorVars = sharedStyles.colors;
  const BASE_BLACK = "#17171F";
  const BrandColor = "#F5C0C0";
  const DEFAULT_BACKGROUND_COLOR = lighten(0.035, BASE_BLACK);

  const FontColor = {
    DEFAULT: ColorVars.LIGHT_GRAY3,
    MUTED: ColorVars.GRAY4,
    PLACEHOLDER: ColorVars.GRAY3,
    LINK: lighten(0.1, ColorVars.BLUE5),
  };

  const IntentColors = {
    PRIMARY: BrandColor,
    SUCCESS: ColorVars.GREEN5,
    WARNING: ColorVars.GOLD5,
    DANGER: ColorVars.RED5,
  };

  const BackgroundColor = {
    DEFAULT: DEFAULT_BACKGROUND_COLOR,
    MUTED: lighten(0.01, BASE_BLACK),
    DARK: lighten(0.02, BASE_BLACK),
    LOGIC: lighten(0.012, BASE_BLACK),
    OVERLAY: rgba(BASE_BLACK, 0.8),
  };

  const BorderColor = {
    DEFAULT: lighten(0.2, BASE_BLACK),
    INVERSE: DEFAULT_BACKGROUND_COLOR,
    MUTED: lighten(0.14, BASE_BLACK),
  };

  return {
    BrandColor,
    ColorVars,
    FontColor,
    IconColor: ColorVars.GRAY4,
    IntentColors,
    BackgroundColor,
    BorderColor,
    FontFamily: {
      DEFAULT: sharedStyles.fontFamily.DEFAULT,
      MONO: sharedStyles.fontFamily.MONO,
    },
    LetterSpacing: {
      DISPLAY: sharedStyles.letterSpacing.DISPLAY,
      HEADING: sharedStyles.letterSpacing.HEADING,
      MARKDOWN_HEADING: sharedStyles.letterSpacing.MARKDOWN_HEADING,
    },
  };
};

export const createDarkMode = (overrides?: PartialThemeVariables): Theme => {
  const base = getDarkModeVariables();

  const {
    BackgroundColor,
    BorderColor,
    BrandColor,
    ColorVars,
    FontColor,
    FontFamily,
    IconColor,
    IntentColors,
    LetterSpacing,
  } = overrides ? merge(base, overrides) : base;
  const HoverColor = rgba(ColorVars.DARK_GRAY5, 0.2);
  /* Useful for situations where content may flow behind the hovered object. E.g. sticky tree component header */
  const NonTransparentHoverColor = darken(0.1, ColorVars.DARK_GRAY5);
  const ActiveColor = rgba(ColorVars.DARK_GRAY5, 0.4);
  const LogoColor = "#F5C0C0";

  const BoxShadow = {
    ACTION_BAR: `
  0 0 0 1px ${rgba(BorderColor.DEFAULT, 0.6)}, 0 4px 10px 0 ${rgba(
    ColorVars.BLACK,
    0.15,
  )}, 0 8px 20px 0 ${rgba(ColorVars.BLACK, 0.25)};
  `,
    BUTTON: `
    inset 0 0 0 1px ${BorderColor.DEFAULT};`,
    CARD: `
    0 0 0 1px ${rgba(BorderColor.DEFAULT, 0.8)},
    0 0 2px ${rgba(ColorVars.BLACK, 0.8)},
    0 2px 4px ${rgba(ColorVars.BLACK, 0.5)};
  `,
    CARD_HOVER: `
    0 0 0 1px ${rgba(BorderColor.DEFAULT, 1)},
    0 0 2px ${rgba(BorderColor.DEFAULT, 0.3)},
    0 5px 10px 0 ${rgba(ColorVars.BLACK, 0.9)};
  `,
    CARD_FLAT_HOVER: `
    0 2px 8px 0 ${rgba(ColorVars.BLACK, 0.6)};
  `,
    CARD_SUBTLE: `
    0 0 0 1px ${rgba(BorderColor.DEFAULT, 0.6)},
    0 0 2px ${rgba(ColorVars.BLACK, 0.5)},
    0 2px 4px ${rgba(ColorVars.BLACK, 0.3)};
  `,
    CARD_SUBTLE_HOVER: `
    0 0 0 1px ${rgba(BorderColor.DEFAULT, 0.8)},
    0 0 2px ${rgba(BorderColor.DEFAULT, 0.1)},
    0 5px 10px 0 ${rgba(ColorVars.BLACK, 0.5)};
  `,
    CARD_SMALL: `
    0 0 0 1px ${rgba(BorderColor.DEFAULT, 0.7)},
    0 1px 1px 0 ${rgba(ColorVars.BLACK, 0.2)};
  `,
    CELL: `
    0 0 0 1px ${BorderColor.MUTED};
  `,
    CELL_HOVER: `
    0 0 0 1px ${BorderColor.MUTED};
  `,
    CELL_FOCUS: `
    0 0 0 1px ${rgba(BrandColor, 0.5)},
    0 0 0 2px ${rgba(BrandColor, 0.05)};
  `,
    CELL_SUCCESS: `
    0 0 0 1px ${ColorVars.GREEN3},
    0 0 0 3px ${rgba(ColorVars.GREEN3, 0.15)},
    0 8px 16px 0 ${rgba(ColorVars.BLACK, 0.05)},
    0 1px 4px 0 ${rgba(ColorVars.BLACK, 0.1)};
  `,
    CELL_WARNING: `
    0 0 0 1px ${ColorVars.GOLD3},
    0 0 0 3px ${rgba(ColorVars.GOLD3, 0.15)},
    0 8px 16px 0 ${rgba(ColorVars.BLACK, 0.05)},
    0 1px 4px 0 ${rgba(ColorVars.BLACK, 0.1)};
  `,
    CELL_DANGER: `
    0 0 0 1px ${ColorVars.RED3},
    0 0 0 3px ${rgba(ColorVars.RED3, 0.15)},
    0 8px 16px 0 ${rgba(ColorVars.BLACK, 0.05)},
    0 1px 4px 0 ${rgba(ColorVars.BLACK, 0.1)};
  `,
    COLOR_SWATCH: `
    inset 0 0 0 1px ${rgba(ColorVars.LIGHT_GRAY1, 0.2)}
  `,
    COLOR_SWATCH_HOVER: `
    inset 0 0 0 1px ${rgba(ColorVars.LIGHT_GRAY1, 0.4)}, inset 0 0 0 3px ${rgba(
      ColorVars.LIGHT_GRAY1,
      0.12,
    )}
  `,
    DIALOG: `
    0 0 0 1px ${rgba(ColorVars.LIGHT_GRAY1, 0.1)},
    0 4px 8px ${rgba(ColorVars.BLACK, 0.4)},
    0 18px 46px 6px ${rgba(ColorVars.BLACK, 0.6)};
  `,
    EXPLORE_DROP_AREA: `
    0 0 6px 0 ${rgba(ColorVars.LIGHT_GRAY1, 0.12)};
  `,
    FOCUS_PRIMARY: `
    0 0 0 0 ${rgba(ColorVars.BLACK, 0)},
    0 0 0 0 ${rgba(ColorVars.BLACK, 0)},
    inset 0 0 0 1px ${ColorVars.BLUE3},
    inset 0 0 0 3px ${rgba(ColorVars.BLUE3, 0.1)};
  `,
    FOCUS_WARNING: `
    0 0 0 1px ${ColorVars.GOLD3},
    0 0 0 3px ${rgba(ColorVars.GOLD3, 0.3)},
    inset 0 1px 1px ${rgba(ColorVars.GOLD3, 0.2)};
  `,
    FOCUS_ERROR: `
    0 0 0 1px ${ColorVars.RED3},
    0 0 0 3px ${rgba(ColorVars.RED3, 0.3)},
    inset 0 1px 1px ${rgba(ColorVars.RED3, 0.2)};
  `,
    FULL_PAGE_DIALOG_RED: `
    0 8px 24px 0 ${rgba(sharedStyles.marketingColors.ROSE_QUARTZ, 0.2)},
    0 2px 4px 0 ${rgba(sharedStyles.marketingColors.ROSE_QUARTZ, 0.24)},
    0 0 0 1px ${rgba(sharedStyles.marketingColors.ROSE_QUARTZ, 0.04)};
  `,
    FULL_PAGE_DIALOG_BLUE: `
    0 8px 24px 0 ${rgba(ColorVars.BLUE5, 0.2)},
    0 2px 4px 0 ${rgba(ColorVars.BLUE5, 0.24)},
    0 0 0 1px ${rgba(ColorVars.BLUE5, 0.04)};
  `,
    FULL_PAGE_DIALOG_PURPLE: `
    0 8px 24px 0 ${rgba(ColorVars.VIOLET5, 0.2)},
    0 2px 4px 0 ${rgba(ColorVars.VIOLET5, 0.24)},
    0 0 0 1px ${rgba(ColorVars.VIOLET5, 0.04)};
  `,
    INPUT: `
    inset 0 0 0 1px ${rgba(BorderColor.DEFAULT, 1)},
    inset 0 0 0 3px ${rgba(BrandColor, 0)};
  `,
    INPUT_FOCUS: `
    inset 0 0 0 1px ${BrandColor},
    inset 0 0 0 3px ${rgba(BrandColor, 0.2)};
  `,
    NAV_BAR: `0 1px 0 0 ${BorderColor.MUTED}`,
    POPOVER: `
  0 0 0 1px ${rgba(ColorVars.LIGHT_GRAY1, 0.2)}, 0 3px 6px ${rgba(
    ColorVars.BLACK,
    0.58,
  )},
    0 5px 10px 0 ${rgba(ColorVars.BLACK, 0.56)}`,
    SWITCH: `inset 0 0 0 1px ${lighten(0.2, BorderColor.DEFAULT)}`,
    TOAST: `
  0 3px 6px ${rgba(ColorVars.BLACK, 0.58)},
    0 5px 10px 0 ${rgba(ColorVars.BLACK, 0.56)}`,
  };

  const PillColor = {
    RED: {
      backgroundColor: rgba(ColorVars.RED4, 0.1),
      color: ColorVars.RED5,
    },
    COBALT: {
      backgroundColor: rgba(ColorVars.COBALT5, 0.15),
      color: ColorVars.COBALT5,
    },
    VIOLET: {
      backgroundColor: rgba(ColorVars.VIOLET5, 0.12),
      color: ColorVars.VIOLET5,
    },
    INDIGO: {
      backgroundColor: rgba(ColorVars.INDIGO5, 0.12),
      color: ColorVars.INDIGO5,
    },
    FOREST: {
      backgroundColor: rgba(ColorVars.FOREST4, 0.1),
      color: ColorVars.FOREST5,
    },
    GREEN: {
      backgroundColor: rgba(ColorVars.GREEN4, 0.12),
      color: ColorVars.GREEN5,
    },
    GRAY: {
      backgroundColor: rgba(ColorVars.LIGHT_GRAY3, 0.12),
      color: ColorVars.LIGHT_GRAY3,
    },
    GRAY_HOVER: {
      backgroundColor: rgba(ColorVars.LIGHT_GRAY3, 0.2),
      color: ColorVars.LIGHT_GRAY3,
    },
    WHITE: {
      backgroundColor: rgba(ColorVars.WHITE, 0.1),
      color: ColorVars.LIGHT_GRAY5,
    },
    TURQUOISE: {
      backgroundColor: rgba(ColorVars.TURQUOISE5, 0.1),
      color: ColorVars.TURQUOISE4,
    },
  };

  const DragAndDrop = {
    selectedElementBorderColor: ColorVars.COBALT5,
    dragPreview: {
      backgroundNew: rgba(ColorVars.COBALT5, 0.07),
      backgroundOther: rgba(ColorVars.COBALT5, 0.03),
      borderColor: rgba(ColorVars.COBALT5, 0.4),
      border: `1px solid ${rgba(ColorVars.COBALT5, 0.4)}`,
      color: ColorVars.COBALT5,
      text: css`
        padding: 2px 5px;

        color: ${({ theme }) => theme.colors.COBALT5};

        background: ${({ theme }) =>
          mix(0.6, BackgroundColor.DEFAULT, theme.colors.COBALT1)};
        border-radius: ${({ theme }) => theme.borderRadius};
      `,
    },
    dragPreviewBlocked: {
      background: rgba(ColorVars.RED5, 0.07),
      border: `1px solid ${rgba(ColorVars.RED5, 0.4)}`,
      color: ColorVars.RED1,
      text: css`
        padding: 2px 5px;

        color: ${({ theme }) => theme.colors.RED1};

        background: ${({ theme }) => lighten(0.35, theme.colors.RED4)};
        border-radius: ${({ theme }) => theme.borderRadius};
      `,
    },
  };

  const TableStyles: Theme["table"] = {
    activeCalcCellBackground: rgba(ColorVars.COBALT5, 0.1),
    activeCalcHeaderBackground: rgba(ColorVars.COBALT5, 0.15),
    activeCalcBorderColor: ColorVars.COBALT4,
    loadingGhostColor: rgba(ColorVars.LIGHT_GRAY1, 0.1),
    placeholderCellBackgroundColor: BackgroundColor.MUTED,
  };

  const CodeColors: CodeColorsType = {
    general: {
      DEFAULT: FontColor.DEFAULT,
      INVALID: ColorVars.RED5,
      SELECTION: ColorVars.DARK_GRAY5,
      IDENTIFIER: FontColor.DEFAULT,
      DELIMITER: FontColor.DEFAULT,
      COMMENT: FontColor.MUTED,
      BOOLEAN: ColorVars.BLUE5,
      STRING: ColorVars.RED5,
      KEYWORD: ColorVars.COBALT5,
      NUMBER: ColorVars.TURQUOISE5,
      TAG: ColorVars.RED5,
    },
    appView: {
      BACKGROUND: darken(0.02, BackgroundColor.DEFAULT),
    },
    html: {
      ATTRIBUTE: ColorVars.COBALT5,
      STRING: ColorVars.TURQUOISE5,
    },
    css: {
      DEFAULT: ColorVars.ORANGE5,
    },
    sql: {
      PREDEFINED: ColorVars.ROSE5,
    },
    calc: {
      COLUMN: ColorVars.GREEN4,
      PARAMETER_REFERENCE: PillColor.COBALT.color,
    },
  };

  /**
   * Custom monaco theme definition.
   *
   * Tokens can be inspected in a live editor using `F1 > Developer: Inspect Tokens`
   *
   * monaco color definitions: https://github.com/microsoft/vscode/blob/main/src/vs/platform/theme/common/colorRegistry.ts
   *                           https://github.com/microsoft/vscode/blob/main/src/vs/editor/common/view/editorColorRegistry.ts
   *
   * monaco token defintions: https://github.com/microsoft/vscode/blob/94c9ea46838a9a619aeafb7e8afd1170c967bb55/src/vs/workbench/services/themes/common/colorThemeSchema.ts#L14
   *
   * monaco base theme: https://github.com/Microsoft/vscode/blob/main/src/vs/editor/standalone/common/themes.ts
   */
  const DARK_MONACO_THEME: Editor.IStandaloneThemeData = {
    base: "vs",
    // see https://github.com/microsoft/monaco-editor/issues/1631 for values
    colors: {
      "editor.foreground": ColorVars.WHITE,

      "editorCursor.foreground": ColorVars.WHITE,

      "editor.selectionBackground": ColorVars.DARK_GRAY5,
      // For some reason these have to be hex values. If you use rgba it will
      // break and fall back to 0xFF0000.  AA = 0.66 opacity
      "editor.selectionHighlightBackground": `${ColorVars.GRAY1}AA`,
      "editor.inactiveSelectionBackground": `${ColorVars.GRAY1}AA`,
      "editor.wordHighlightBackground": `${ColorVars.GRAY1}AA`,
      // A bit different than the above colors so they don't run together
      "editorBracketMatch.background": `${ColorVars.GRAY1}77`,

      "editorIndentGuide.background": ColorVars.DARK_GRAY5,
      "editorIndentGuide.activeBackground": ColorVars.GRAY1,

      "editorLineNumber.foreground": FontColor.MUTED,

      // 33 = 0.2 opacity
      "diffEditor.insertedTextBackground": `${ColorVars.GREEN5}33`,
      "diffEditor.removedTextBackground": `${ColorVars.RED5}33`,
      "diffEditor.diagonalFill": `${ColorVars.LIGHT_GRAY3}`,

      // Outline color for the entire editor
      focusBorder: BackgroundColor.DEFAULT,

      //////
      // Autocomplete colors
      //////

      // Background color of the suggestion widget
      "editorSuggestWidget.background": BackgroundColor.DEFAULT,

      // Foreground (default text) color of the suggestion widget.
      "editorSuggestWidget.foreground": FontColor.DEFAULT,

      // Color for inline completions
      "editorGhostText.foreground": FontColor.MUTED,

      ////////
      /// SELECTED ROWS

      // Color of the match highlights in the suggest widget when an item is focused.
      // The selected row
      "editorSuggestWidget.focusHighlightForeground": ColorVars.BLUE5,

      // Foreground color of the selected entry in the suggest widget.
      // This is the non-higlighted text part of the selected row
      // CC = 0.8 opacity
      "editorSuggestWidget.selectedForeground": FontColor.DEFAULT,

      // Background color of the selected entry in the suggestion widget.
      "editorSuggestWidget.selectedBackground": lighten(
        0.06,
        BackgroundColor.MUTED,
      ),

      // Icon foreground color of the selected entry in the suggest widget.
      "editorSuggestWidget.selectedIconForeground": ColorVars.WHITE,

      ////////
      /// NOT SELECTED ROWS

      // Color of the match highlights in the suggestion widget.
      // The non-selected row
      "editorSuggestWidget.highlightForeground": ColorVars.BLUE5,

      // The background color of non-selected autocomplete results when hovered over with the mouse
      // Intended to match HoverColor (cannot use RGBA - see above)
      "list.hoverBackground": `${ColorVars.DARK_GRAY5}59`, // 59 = 0.35 opacity: ;
    },
    inherit: true,
    rules: generateMonacoRules(CodeColors),
  };

  const MAGIC_COLORS = {
    backgroundGradient: `linear-gradient(
    270deg,
    rgba(255, 192, 202, 0.05) 0%,
    rgba(249, 165, 148, 0.03) 100%
  )`,
    backgroundColor: "#1E1D25",
    primaryColor: "#FFC0CA",
    secondaryColor: "#FDAAAA",
    tertiaryColor: "#F9A594",
  };

  const MAGIC_DIFF_COLORS = {
    diffInsertColor: `${ColorVars.GREEN5}1A`,
    diffDeleteColor: `${ColorVars.RED5}1A`,
    diffFillerColor: `${ColorVars.LIGHT_GRAY5}1A`,
  };

  const MAGIC_SHADOWS = {
    magicModalShadow: `
    0 0 0 1px ${rgba(MAGIC_COLORS.primaryColor, 0.15)},
    0 4px 8px ${rgba(MAGIC_COLORS.primaryColor, 0.05)},
    0 18px 50px 6px ${rgba(MAGIC_COLORS.primaryColor, 0.08)};`,
    promptBarShadow: `0 0 10px 0 ${rgba(MAGIC_COLORS.primaryColor, 0.1)};`,
  };
  return {
    ...sharedStyles,
    fontFamily: FontFamily,
    letterSpacing: LetterSpacing,

    isCustomTheme: false,
    type: "DARK",

    activeColor: ActiveColor,
    ansi: {
      BLACK_FG: ColorVars.GRAY2,
      RED_FG: ColorVars.RED5,
      GREEN_FG: ColorVars.GREEN3,
      YELLOW_FG: ColorVars.GOLD2,
      BLUE_FG: ColorVars.BLUE5,
      MAGENTA_FG: ColorVars.ROSE4,
      CYAN_FG: ColorVars.TURQUOISE5,
      WHITE_FG: ColorVars.WHITE,
      BRIGHT_BLACK_FG: ColorVars.GRAY4,
      BRIGHT_RED_FG: ColorVars.VERMILION4,
      BRIGHT_GREEN_FG: ColorVars.GREEN5,
      BRIGHT_YELLOW_FG: ColorVars.GOLD5,
      BRIGHT_BLUE_FG: ColorVars.COBALT5,
      BRIGHT_MAGENTA_FG: ColorVars.ROSE5,
      BRIGHT_CYAN_FG: ColorVars.TURQUOISE5,
      BRIGHT_WHITE_FG: ColorVars.WHITE,
    },
    appBuilderRowHover: ColorVars.DARK_GRAY5,
    backgroundColor: BackgroundColor,
    bgTextureOpacity: 0.01,
    borderColor: BorderColor,
    boxShadow: BoxShadow,
    button: {
      default: {
        primary: {
          fontColor: BrandColor,
          backgroundColor: rgba(BrandColor, 0.1),
          borderColor: rgba(BrandColor, 0.2),
        },
        success: {
          fontColor: ColorVars.GREEN5,
          backgroundColor: ColorVars.GREEN5,
          borderColor: rgba(ColorVars.GREEN5, 0.2),
        },
        warning: {
          fontColor: ColorVars.GOLD5,
          backgroundColor: ColorVars.GOLD5,
          borderColor: rgba(ColorVars.GOLD5, 0.2),
        },
        danger: {
          fontColor: ColorVars.RED5,
          backgroundColor: ColorVars.RED5,
          borderColor: rgba(ColorVars.RED5, 0.2),
        },
        none: {
          fontColor: FontColor.DEFAULT,
          backgroundColor: BackgroundColor.DEFAULT,
          borderColor: BorderColor.DEFAULT,
        },
      },
      subtleOrMinimal: {
        primary: {
          fontColor: lighten(0.1, ColorVars.BLUE5),
          backgroundColor: ColorVars.BLUE5,
        },
        success: {
          fontColor: ColorVars.GREEN5,
          backgroundColor: ColorVars.GREEN5,
        },
        warning: {
          fontColor: ColorVars.GOLD5,
          backgroundColor: ColorVars.GOLD5,
        },
        danger: {
          fontColor: ColorVars.RED5,
          backgroundColor: ColorVars.RED5,
        },
        none: {
          fontColor: FontColor.DEFAULT,
          backgroundColor: ColorVars.GRAY3,
        },
      },
    },
    calculationsColor: {
      icon: ColorVars.COBALT5,
      background: rgba(ColorVars.COBALT5, 0.1),
    },
    codeBackground: ColorVars.DARK_GRAY5,
    colorSwatchPlaceholder: ColorVars.DARK_GRAY5,
    componentsColor: {
      foreground: ColorVars.ROSE5,
      background: rgba(ColorVars.ROSE5, 0.1),
    },
    conditionalFormatting: {
      cell: {
        [ConditionalFormattingCellStyle.RED]: {
          backgroundColor: mix(0.6, BackgroundColor.DEFAULT, ColorVars.RED1),
          color: lighten(0.2, ColorVars.RED5),
        },
        [ConditionalFormattingCellStyle.ORANGE]: {
          backgroundColor: mix(0.6, BackgroundColor.DEFAULT, ColorVars.ORANGE1),
          color: lighten(0.2, ColorVars.ORANGE5),
        },
        [ConditionalFormattingCellStyle.YELLOW]: {
          backgroundColor: mix(0.6, BackgroundColor.DEFAULT, ColorVars.GOLD1),
          color: lighten(0.1, ColorVars.GOLD5),
        },
        [ConditionalFormattingCellStyle.GREEN]: {
          backgroundColor: mix(0.6, BackgroundColor.DEFAULT, ColorVars.GREEN1),
          color: lighten(0.1, ColorVars.GREEN5),
        },
        [ConditionalFormattingCellStyle.BLUE]: {
          backgroundColor: mix(0.6, BackgroundColor.DEFAULT, ColorVars.BLUE1),
          color: lighten(0.2, ColorVars.BLUE5),
        },
        [ConditionalFormattingCellStyle.PURPLE]: {
          backgroundColor: mix(0.6, BackgroundColor.DEFAULT, ColorVars.VIOLET1),
          color: lighten(0.2, ColorVars.VIOLET5),
        },
        [ConditionalFormattingCellStyle.GRAY]: {
          backgroundColor: mix(0.6, BackgroundColor.DEFAULT, ColorVars.GRAY1),
          color: lighten(0.2, ColorVars.GRAY5),
        },
        [ConditionalFormattingCellStyle.TURQUOISE]: {
          backgroundColor: mix(
            0.6,
            BackgroundColor.DEFAULT,
            ColorVars.TURQUOISE1,
          ),
          color: lighten(0.1, ColorVars.TURQUOISE5),
        },
        [ConditionalFormattingCellStyle.LIME]: {
          backgroundColor: mix(0.6, BackgroundColor.DEFAULT, ColorVars.LIME1),
          color: lighten(0.1, ColorVars.LIME5),
        },
        [ConditionalFormattingCellStyle.PINK]: {
          backgroundColor: mix(0.6, BackgroundColor.DEFAULT, ColorVars.ROSE1),
          color: lighten(0.2, ColorVars.ROSE5),
        },
      },
      gradients: {
        [ConditionalFormattingGradientStyle.GREEN_WHITE]: {
          colors: [ColorVars.GREEN2, BackgroundColor.DEFAULT],
        },
        [ConditionalFormattingGradientStyle.BLUE_WHITE]: {
          colors: [ColorVars.BLUE2, BackgroundColor.DEFAULT],
        },
        [ConditionalFormattingGradientStyle.RED_WHITE]: {
          colors: [ColorVars.RED2, BackgroundColor.DEFAULT],
        },
        [ConditionalFormattingGradientStyle.WHITE_GREEN]: {
          colors: [BackgroundColor.DEFAULT, ColorVars.GREEN2],
        },
        [ConditionalFormattingGradientStyle.WHITE_BLUE]: {
          colors: [BackgroundColor.DEFAULT, ColorVars.BLUE2],
        },
        [ConditionalFormattingGradientStyle.WHITE_RED]: {
          colors: [BackgroundColor.DEFAULT, ColorVars.RED2],
        },
        [ConditionalFormattingGradientStyle.GREEN_WHITE_RED]: {
          colors: [ColorVars.GREEN2, BackgroundColor.DEFAULT, ColorVars.RED2],
        },
        [ConditionalFormattingGradientStyle.GREEN_YELLOW_RED]: {
          colors: [ColorVars.GREEN2, ColorVars.GOLD2, ColorVars.RED2],
        },
        [ConditionalFormattingGradientStyle.RED_YELLOW_GREEN]: {
          colors: [ColorVars.RED2, ColorVars.GOLD2, ColorVars.GREEN2],
        },
        [ConditionalFormattingGradientStyle.RED_WHITE_GREEN]: {
          colors: [ColorVars.RED2, BackgroundColor.DEFAULT, ColorVars.GREEN2],
        },
        [ConditionalFormattingGradientStyle.RED_WHITE_BLUE]: {
          colors: [ColorVars.RED2, BackgroundColor.DEFAULT, ColorVars.BLUE2],
        },
        [ConditionalFormattingGradientStyle.BLUE_WHITE_RED]: {
          colors: [ColorVars.BLUE2, BackgroundColor.DEFAULT, ColorVars.RED2],
        },
      },
    },
    dragAndDrop: DragAndDrop,
    fontColor: FontColor,
    globalCSSVars: css`
      --hex-animation-duration: ${({ theme }) => theme.animation.duration};
      --hex-animation-easing: ${({ theme }) => theme.animation.easing};
      --hex-font-family-default: ${({ theme }) => theme.fontFamily.DEFAULT};
      --hex-font-color-default: ${({ theme }) => theme.fontColor.DEFAULT};
      --hex-font-color-muted: ${({ theme }) => theme.fontColor.MUTED};
      --hex-font-color-placeholder: ${({ theme }) =>
        theme.fontColor.PLACEHOLDER};
      --hex-font-size-default: ${({ theme }) => theme.fontSize.DEFAULT};
      --hex-font-size-small: ${({ theme }) => theme.fontSize.SMALL};
      --hex-font-size-extra-small: ${({ theme }) => theme.fontSize.EXTRA_SMALL};
      --hex-font-weight-normal: ${({ theme }) => theme.fontWeight.NORMAL};
      --hex-font-weight-medium: ${({ theme }) => theme.fontWeight.MEDIUM};
      --hex-font-weight-semi-bold: ${({ theme }) => theme.fontWeight.SEMI_BOLD};
      --hex-icon-color: ${({ theme }) => theme.iconColor};
      --hex-bkg-color-default: ${({ theme }) => theme.backgroundColor.DEFAULT};
      --hex-bkg-color-muted: ${({ theme }) => theme.backgroundColor.MUTED};
      --hex-bkg-color-selected: ${({ theme }) =>
        theme.menuItem.activeBackground};
      --hex-border-radius: ${({ theme }) => theme.borderRadius};
      --hex-border-color-default: ${({ theme }) => theme.borderColor.DEFAULT};
      --hex-border-color-muted: ${({ theme }) => theme.borderColor.MUTED};
      --hex-box-shadow-popover: ${({ theme }) => theme.boxShadow.POPOVER};
      --hex-hover-color: ${HoverColor};
      --hex-intent-danger: ${ColorVars.RED5};
      --table-active-calc-border-color: ${({ theme }) =>
        theme.table.activeCalcBorderColor};
      --table-active-calc-background-color: ${({ theme }) =>
        theme.table.activeCalcCellBackground};
      --table-placeholder-cell-background-color: ${({ theme }) =>
        theme.table.placeholderCellBackgroundColor};
      --hex-table-range-selection-border-color: ${({ theme }) =>
        theme.colors.COBALT5};
      --hex-table-range-selection-background-color: ${({ theme }) =>
        rgba(theme.colors.COBALT5, 0.2)};
      --hex-table-range-selection-background-color-opaque: ${({ theme }) =>
        mix(0.8, BackgroundColor.DEFAULT, theme.colors.COBALT5)};
    `,
    graph: {
      dotColor: ColorVars.DARK_GRAY5,
      lineColor: ColorVars.GRAY1,
    },
    magic: { ...MAGIC_COLORS, ...MAGIC_DIFF_COLORS, ...MAGIC_SHADOWS },
    highlightColor: BrandColor,
    hoverColor: HoverColor,
    iconColor: IconColor,
    intent: IntentColors,
    logoColor: LogoColor,

    markdownCodeTheme: (isInAppView, allowScroll) =>
      generateMarkdownCodeTheme(isInAppView, allowScroll, CodeColors),
    markdownStyles: (size) =>
      markdownStyles(size, {
        letterSpacingHeader: LetterSpacing.MARKDOWN_HEADING,
      }),
    menuItem: {
      activeText: BrandColor,
      activeBackground: rgba(BrandColor, 0.08),
    },
    monacoTheme: {
      name: "hexTheme-dark",
      options: DARK_MONACO_THEME,
    },
    monacoPill: {
      RED: {
        ...PillColor.RED,
        css: pillCss(PillColor.RED),
      },
      COBALT: {
        ...PillColor.COBALT,
        css: monacoPillCss(PillColor.COBALT),
      },
      VIOLET: {
        ...PillColor.VIOLET,
        css: monacoPillCss(PillColor.VIOLET),
      },
      INDIGO: {
        ...PillColor.INDIGO,
        css: monacoPillCss(PillColor.INDIGO),
      },
      FOREST: {
        ...PillColor.FOREST,
        css: monacoPillCss(PillColor.FOREST),
      },
      GREEN: {
        ...PillColor.GREEN,
        css: monacoPillCss(PillColor.GREEN),
      },
      GRAY: {
        ...PillColor.GRAY,
        css: monacoPillCss(PillColor.GRAY_HOVER),
      },
      WHITE: {
        ...PillColor.WHITE,
        css: css`
          box-shadow: inset 0 0 0 1px ${BorderColor.MUTED};
          ${monacoPillCss(PillColor.WHITE)}
        `,
      },
      TURQUOISE: {
        ...PillColor.TURQUOISE,
        css: monacoPillCss(PillColor.TURQUOISE),
      },
    },
    NonTransparentHoverColor: NonTransparentHoverColor,
    pill: {
      RED: {
        ...PillColor.RED,
        css: pillCss(PillColor.RED),
      },
      COBALT: {
        ...PillColor.COBALT,
        css: pillCss(PillColor.COBALT),
      },
      VIOLET: {
        ...PillColor.VIOLET,
        css: pillCss(PillColor.VIOLET),
      },
      INDIGO: {
        ...PillColor.INDIGO,
        css: pillCss(PillColor.INDIGO),
      },
      FOREST: {
        ...PillColor.FOREST,
        css: pillCss(PillColor.FOREST),
      },
      GREEN: {
        ...PillColor.GREEN,
        css: pillCss(PillColor.GREEN),
      },
      GRAY: {
        ...PillColor.GRAY,
        css: pillCss(PillColor.GRAY),
      },
      WHITE: {
        ...PillColor.WHITE,
        css: pillCss(PillColor.WHITE),
      },
      TURQUOISE: {
        ...PillColor.TURQUOISE,
        css: pillCss(PillColor.TURQUOISE),
      },
    },
    searchHighlightColor: rgba(ColorVars.GOLD5, 0.3),
    searchHighlightFocusColor: rgba(ColorVars.GOLD5, 0.5),
    statusCategoryColor: {
      [HexColor.RED]: ColorVars.RED5,
      [HexColor.ORANGE]: ColorVars.ORANGE5,
      [HexColor.YELLOW]: ColorVars.GOLD4,
      [HexColor.GREEN]: ColorVars.FOREST4,
      [HexColor.TEAL]: ColorVars.TURQUOISE4,
      [HexColor.BLUE]: ColorVars.COBALT5,
      [HexColor.PURPLE]: ColorVars.INDIGO5,
      [HexColor.PINK]: ColorVars.ROSE5,
      [HexColor.GRAY]: ColorVars.GRAY5,
      [HexColor.LIME]: ColorVars.LIME4,
    },
    stepper: {
      activeStepBackgroundColor: ColorVars.WHITE,
      inactiveStepBackgroundColor: "transparent",
      activeStepFontColor: ColorVars.DARK_GRAY1,
      inactiveStepFontColor: FontColor.MUTED,
    },
    CalloutOrToastBackground: {
      /* This produces the same visual result as rgba(intent color, 0.12)
    but without transparency which we don't want for toasts */
      primary: mix(0.88, BackgroundColor.DEFAULT, IntentColors.PRIMARY),
      success: mix(0.88, BackgroundColor.DEFAULT, IntentColors.SUCCESS),
      warning: mix(0.88, BackgroundColor.DEFAULT, IntentColors.WARNING),
      danger: mix(0.88, BackgroundColor.DEFAULT, IntentColors.DANGER),
      none: BackgroundColor.DEFAULT,
    },
    tour: {
      accentColor: sharedStyles.marketingColors.ROSE_QUARTZ,
      background:
        "linear-gradient(2.03deg, #352F34 6.62%, #18191F 130.98%), linear-gradient(0deg, rgba(218, 221, 230, 0.15), rgba(218, 221, 230, 0.15))",
      fontColor: FontColor.DEFAULT,
      mutedFontColor: FontColor.MUTED,
      pulsingIndicatorInnerColor: ColorVars.WHITE,
      pulsingIndicatorMiddleColor: ColorVars.WHITE,
      pulsingIndicatorOuterColor: sharedStyles.marketingColors.ROSE_QUARTZ,
    },
    bento: {
      // TODO(SM) CORE2-706 coming back to make these better
      jumpBackIn: BrandColor,
      popular: IntentColors.SUCCESS,
      collections: ColorVars.COBALT5,
      recentlyPublished: ColorVars.ORANGE3,
      learn: ColorVars.GOLD3,
    },
    table: TableStyles,
    useInvertedConnectionIcons: true,
    userActionColor: ColorVars.GOLD5, // For comments and stars
    vegaTooltipTheme: "dark",
    unreadIndicator: {
      backgroundColor: BrandColor,
      fontColor: BackgroundColor.DEFAULT,
    },
  };
};

export const DARK_THEME: Theme = createDarkMode();

export const useDarkTheme = (): Theme => {
  return DARK_THEME;
};
