import { Classes, Intent } from "@blueprintjs/core";
import { darken, rgba } from "polished";
import { css } from "styled-components";

import { STATIC_ICON } from "../../components/icons/staticIcons.js";
import { buttonStyles } from "../../hex-components/HexButton.js";

/**
 * Apply this class to the `className` option of `toaster.show()`
 * in order to opt out of most of our default toast styles in `GlobalStyle.ts`.
 * This is useful when making custom toasts with fancier UIs
 * since many of default styles are implemented with !important and hard to override.
 */
export const HEX_CUSTOM_TOAST_CLASS = "hex-custom-toast";

const toastIntentStyles = (intent: Intent) => css`
  ${({ theme }) => {
    const buttonIntent = theme.button.default[intent];
    let fontColor = buttonIntent.fontColor;
    let iconColor = buttonIntent.fontColor;
    let borderColor = rgba(buttonIntent.fontColor, 0.5);
    let buttonBackgroundHoverColor = rgba(buttonIntent.backgroundColor, 0.1);
    let buttonBackgroundActiveColor = rgba(buttonIntent.backgroundColor, 0.2);
    const backgroundColor = theme.CalloutOrToastBackground[intent];

    if (intent === "none") {
      fontColor = theme.fontColor.DEFAULT;
      iconColor = theme.iconColor;
      borderColor = theme.borderColor.DEFAULT;
      buttonBackgroundHoverColor = theme.hoverColor;
      buttonBackgroundActiveColor = theme.activeColor;
    }

    return css`
      /* Wrapper styles */
      color: ${fontColor};
      border: 1px solid ${borderColor};
      background: ${backgroundColor};

      /* Left icon */
      .${Classes.ICON}.${Classes.ICON} svg {
        color: ${iconColor} !important;
      }

      /* Actions */
      .${Classes.BUTTON_GROUP} > .${Classes.BUTTON} {
        color: ${fontColor} !important;
        transition: background-color ${theme.animation.duration}
          ${theme.animation.easing};

        &:not(.${Classes.DISABLED}) {
          &:hover {
            background-color: ${buttonBackgroundHoverColor} !important;
          }

          &:active,
          &.${Classes.ACTIVE} {
            background-color: ${buttonBackgroundActiveColor} !important;
            color: ${darken(0.075, fontColor)} !important;
          }
        }

        > .${Classes.ICON}, > .${Classes.ICON} > svg {
          color: ${iconColor} !important;
        }
      }

      /* Close button */
      /* Hide the bp svg, and then add our own svg code as a background image */
      button[aria-label="Close"] .${Classes.ICON} {
        background-color: ${iconColor};
        background-image: none;
        mask-image: ${`url('data:image/svg+xml,${STATIC_ICON.CrossSmall}')`};
        svg {
          visibility: hidden;
        }
      }
    `;
  }}
`;

export const toastStyles = css`
  .${Classes.TOAST} {
    align-items: center;
    max-width: 650px;

    background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
    box-shadow: ${({ theme }) => theme.boxShadow.TOAST};
    border: 1px solid ${({ theme }) => theme.borderColor.DEFAULT};
    border-radius: ${({ theme }) => theme.borderRadius};

    && a {
      ${({ theme }) => theme.resets.a};
    }

    &:not(.${HEX_CUSTOM_TOAST_CLASS}) {
      .${Classes.BUTTON_GROUP}.${Classes.MINIMAL} {
        .${Classes.BUTTON} {
          ${buttonStyles}
          background: none !important;
          box-shadow: none !important;

          &:hover {
            background-color: ${({ theme }) => theme.hoverColor} !important;
          }
        }
      }

      /* intent specific color */
      &.${Classes.INTENT_PRIMARY} {
        ${toastIntentStyles("primary")};
      }

      &.${Classes.INTENT_SUCCESS} {
        ${toastIntentStyles("success")};
      }

      &.${Classes.INTENT_WARNING} {
        ${toastIntentStyles("warning")};
      }

      &.${Classes.INTENT_DANGER} {
        ${toastIntentStyles("danger")};
      }

      &:not(.${Classes.INTENT_PRIMARY}):not(.${Classes.INTENT_SUCCESS}):not(
          .${Classes.INTENT_WARNING}
        ):not(.${Classes.INTENT_DANGER}) {
        ${toastIntentStyles("none")};
      }
    }
  }
`;
